import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'parseDate',
    standalone: false
})
export class ParseDatePipe implements PipeTransform {

  transform(value: string): Date {
    return value ? new Date(value) : null;
  }

}