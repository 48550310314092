import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { GlobalBusyService, NotificationService, Program, ProgramsService, Project, ProjectsService, SlideShowImage } from 'app/shared/services';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { Observable, Subject, zip } from 'rxjs';
import { delay } from 'rxjs/operators';
import { map, mergeMap } from 'rxjs/operators';




@Component({
    selector: 'miradi-edit-photos-dialog',
    templateUrl: './edit-photos.dialog.html',
    styleUrls: ['./edit-photos.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EditPhotosDialog extends BaseDialog {

  readonly baseUrl: string;
  readonly maxFileSize = 3 * 1024 * 1024;

  programOrProject: Program | Project;

  originalPhotos: SlideShowImage[];
  photos: SlideShowImage[];
  selectedPhotos: any[] = []; // SlideShowImage[] | SlideShowImageNew[];

  isBusy: boolean;
  isDirty: boolean;
  emptyFiles: File[];

  constructor(
    authService: AuthService,
    private globalBusyService: GlobalBusyService,
    cdr: ChangeDetectorRef,
    el: ElementRef,
    private notificationService: NotificationService,
    private programsService: ProgramsService,
    private projectsService: ProjectsService,
    private translateService: TranslateService,
  ) {
    super(authService, cdr, el);

    this.baseUrl = environment.environmentBaseUrl;

    this.emptyFiles = [];
  }

  init(data: any) {
    this.isDirty = false;
    this.selectedPhotos = [];

    if (data) {
      this.programOrProject = data.programOrProject;
      this.originalPhotos = data.photos;
      this.photos = JSON.parse(JSON.stringify(data.photos)); // copy of array
    } else {
      this.programOrProject = undefined;
      this.originalPhotos = undefined;
      this.photos = undefined;
    }
  }

  deletePhotoLocally(photo: SlideShowImage) {
    if ((photo as any).$imageLocalUrl || photo.imageThumbnailUrl) {
      const index = this.photos.findIndex((p: SlideShowImage) => {
        if (photo.resourceIdentifier) {
          return photo.resourceIdentifier === p.resourceIdentifier;
        } else {
          return !p.resourceIdentifier && (photo as any).$imageLocalUrl === (p as any).$imageLocalUrl;
        }
      });
      this.photos.splice(index, 1);

      this.selectedPhotos = [];
      this.isDirty = true;
      this.cdr.markForCheck();
    }
  }

  selectFile(ev: any, photo: any) {
    if (ev && ev.files && ev.files.length) { // IE11 hack: IE calls this method twice: one with a file and another without one...
      let file: File = ev.files[0];
      if (file.size < this.maxFileSize) {
        var reader = new FileReader();
        reader.onload = (e) => {
          photo.$file = file;
          photo.$imageLocalUrl = (e.target as any).result;

          this.isDirty = true;
          this.cdr.markForCheck();
        };
        reader.readAsDataURL(file);

        if (!photo.resourceIdentifier && !photo.$file) {
          this.photos.push(photo);
        }
      } else {
        this.notificationService.error(
          this.translateService.instant('Invalid file size'),
          this.translateService.instant('Maximum allowed file size is {{maxSize}}MB.', { maxSize: this.maxFileSize / 1000000}),
        )
      }
    }
  }

  save() {
    if (!this.isDirty) return;

    this.globalBusyService.setBusy(true);

    // start by re-ordering the files according to WYSIWYG
    for (let i = 0; i < this.photos.length; i++) {
      (this.photos[i] as any).imageOrder = i + 1;
    }

    // delete items remotely if required
    this.deletePhotosRemotely()
    .pipe(
      // add items remotely if required
      mergeMap(this.addPhotosRemotely.bind(this)),
      // update items remotely if required
      mergeMap(this.updatePhotosRemotely.bind(this)),
    ).subscribe(() => {
      this.notificationService.success(
        this.translateService.instant('Success'),
        this.translateService.instant('Slideshow updated successfully.'),
      );

      this.photos = this.photos.filter((pp: SlideShowImage) => {
        return !!pp.resourceIdentifier;
      });

      this.globalBusyService.setBusy(false);

      this.close(this.photos);
    }, (error: any) => {
      this.globalBusyService.setBusy(false);

      this.cdr.markForCheck();
    })
  }

  private deletePhotosRemotely(): Observable<any> {
    const deletedPhotos = this.originalPhotos.filter((originalPhoto: SlideShowImage) => {
      return this.photos.findIndex((photo: SlideShowImage) => {
        return photo.resourceIdentifier === originalPhoto.resourceIdentifier;
      }) < 0;
    });
    if (deletedPhotos && deletedPhotos.length) {
      return zip(
        ...deletedPhotos.map((deletedPhoto: SlideShowImage) => {
          return (this.programOrProject instanceof Project ?
          this.projectsService.deleteProjectSlideshowImage(
            this.programOrProject.identifier,
            deletedPhoto.resourceIdentifier
          ) : this.programsService.deleteProgramSlideshowImage(
            this.programOrProject.identifier,
            deletedPhoto.resourceIdentifier
          ));
        })
      );
    } else {
      return of(null)
      .pipe(
        delay(10)
      );
    }
  }

  private addPhotosRemotely(): Observable<any> {
    const newPhotos = this.photos.filter((photo: SlideShowImage) => {
      return !photo.resourceIdentifier && (photo as any).$file;
    });
    if (newPhotos && newPhotos.length) {
      return zip(
        ...newPhotos.map((newPhoto: SlideShowImage) => {
          return (this.programOrProject instanceof Project ?
          this.projectsService.createProjectSlideshowImage(
            this.programOrProject.identifier,
            (newPhoto as any).$file,
            newPhoto.caption || '',
            newPhoto.imageOrder,
          ) : this.programsService.createProgramSlideshowImage(
            this.programOrProject.identifier,
            (newPhoto as any).$file,
            newPhoto.caption || '',
            newPhoto.imageOrder,
          )).pipe(
            map((savedPhoto: SlideShowImage) => {
              Object.assign(newPhoto, savedPhoto);
              (newPhoto as any).$file = undefined;
              (newPhoto as any).$imageLocalUrl = undefined;
              return null;
            })
          );
        })
      );
    } else {
      return of(null)
      .pipe(
        delay(10)
      );
    }
  }

  private updatePhotosRemotely(): Observable<any> {
    const updatedPhotos = this.photos.filter((photo: SlideShowImage) => {
      return this.originalPhotos.findIndex((originalPhoto: SlideShowImage) => {
        return originalPhoto.resourceIdentifier === photo.resourceIdentifier &&
        (originalPhoto.caption !== photo.caption || originalPhoto.imageOrder !== photo.imageOrder || !!(photo as any).$file);
      }) >= 0;
    });
    if (updatedPhotos && updatedPhotos.length) {
      return zip(
        ...updatedPhotos.map((updatedPhoto: SlideShowImage) => {
          return (this.programOrProject instanceof Project ?
          this.projectsService.updateProjectSlideshowImage(
            this.programOrProject.identifier,
            updatedPhoto.resourceIdentifier,
            (updatedPhoto as any).$file,
            updatedPhoto.caption,
            updatedPhoto.imageOrder,
          ) : this.programsService.updateProgramSlideshowImage(
            this.programOrProject.identifier,
            updatedPhoto.resourceIdentifier,
            (updatedPhoto as any).$file,
            updatedPhoto.caption,
            updatedPhoto.imageOrder,
          )).pipe(
            map(() => {
              (updatedPhoto as any).$cacheBusting = Date.now();
              return null;
            })
          );
        })
      );
    } else {
      return of(null)
      .pipe(
        delay(10)
      );
    }
  }



  simulateFileInputClick(el: any) {
    setTimeout(() => {
      el.click();
    }, 10);
  }

}
