import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormFieldComponent } from 'app/shared/components/form-field/form-field.component';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { AssociatedLink, AssociatedLinkNew, GlobalBusyService, NotificationService } from 'app/shared/services';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { Dialog } from 'primeng/dialog';
import { Observable } from 'rxjs';



@Component({
    selector: 'miradi-add-link-dialog',
    templateUrl: './add-link.dialog.html',
    styleUrls: ['./add-link.dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AddLinkDialog extends BaseDialog {

  @ViewChild(Dialog, { static: true }) dialog: Dialog;
  @ViewChildren(FormFieldComponent) fields: QueryList<FormFieldComponent>;

  readonly validationObject: any;

  editableItem: AssociatedLink;
  saveCallback: (link: AssociatedLink) => Observable<any>;

  constructor(
    authService: AuthService,
    private globalBusyService: GlobalBusyService,
    cdr: ChangeDetectorRef,
    el: ElementRef,
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {
    super(authService, cdr, el);

    this.validationObject = AssociatedLinkNew.getValidationObject();
  }

  init(data: any) {
    this.validationMessages = [];

    if (data) {
      this.saveCallback = data.saveCallback;
      this.editableItem = new AssociatedLink(data.editableItem);
      this.editableItem.uri = this.editableItem.uri || '';
    } else {
      this.saveCallback = undefined;
      this.editableItem = undefined;
    }
  }

  save() {
    this.validationMessages = [];
    this.cdr.markForCheck();

    this.fields.forEach((field: FormFieldComponent) => {
      field.forceValidate();
    });

    if (!this.validationObject.label.isValid(this.editableItem)) {
      this.validationMessages.push({
        severity: 'error',
        summary: this.translateService.instant('Label:'),
        detail: this.translateService.instant('Invalid value.'),
      });
      this.cdr.markForCheck();
    } else if (!this.validationObject.uri.isValid(this.editableItem)) {
      this.validationMessages.push({
        severity: 'error',
        summary: this.translateService.instant('URL:'),
        detail: this.translateService.instant('Invalid value.'),
      });
      this.cdr.markForCheck();
    } else {
      this.globalBusyService.setBusy(true);

      this.editableItem.uri = this.editableItem.uri || '';
      this.saveCallback(this.editableItem)
      .subscribe((newItem: AssociatedLink) => {
        this.globalBusyService.setBusy(false);
        this.notificationService.success(
          this.translateService.instant('Success'),
          this.translateService.instant('Changes saved successfully.')
        );

        this.close(newItem || this.editableItem);
      }, (error: any) => {
        this.globalBusyService.setBusy(false);
        this.cdr.markForCheck();
      });
    }
  }

}
