<p-dialog
  styleClass="add-link"
  [closable]="false"
  [closeOnEscape]="false"
  [modal]="true"
  [dismissableMask]="false"
  [(visible)]="isVisible"
  (onHide)="editableItem = undefined">

  <ng-template #header>
    <span>
      {{ 'Add Link' | translate }}
    </span>
  </ng-template>

  <p-message *ngFor="let message of validationMessages || []"
    [closable]="false"
    [severity]="message.severity"
    [text]="message.summary + ' ' + message.detail">
  </p-message>

  <div *ngIf="editableItem"
    class="content form">

    <miradi-form-field
      [autoFocus]="true"
      [editActive]="true"
      [label]="'Label' | translate"
      type="text"
      [validationObject]="validationObject?.label"
      [(ngModel)]="editableItem.label">
    </miradi-form-field>

    <miradi-form-field
      [editActive]="true"
      [label]="'URL' | translate"
      type="text"
      [validationObject]="validationObject?.uri"
      [(ngModel)]="editableItem.uri">
    </miradi-form-field>

  </div>

  <ng-template #footer>
    <p-button
      [text]="true"
      [label]="'Cancel' | translate"
      (click)="dismiss()">
    </p-button>

    <p-button
      icon="fas fa-check"
      [label]="'Save' | translate"
      (click)="save()"
      [raised]="true"
      severity="primary">
    </p-button>
  </ng-template>
</p-dialog>

