import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialog } from 'app/shared/dialogs';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { AppService, NotificationService, UserService } from 'app/shared/services';
import { MiradiLicense } from 'app/shared/services/api-generated/model/miradiLicense';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { DateUtils, MomentUtils } from 'app/shared/utils';
import { BaseComponent } from '../base/base.component';



@Component({
    selector: 'miradi-eula-banner',
    templateUrl: './eula-banner.component.html',
    styleUrls: ['./eula-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EulaBannerComponent extends BaseComponent implements OnInit {

  @ViewChild(ConfirmDialog, { static: true }) confirmDialog: BaseDialog;

  miradiLicense: MiradiLicense;
  showBanner: boolean;

  constructor(
    private appService: AppService,
    authService: AuthService,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private router: Router,
    private userService: UserService,
    private translateService: TranslateService,
  ) {
    super(authService);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.appService.listenToEulaAccepted()
      .subscribe(() => {
        this.refresh();
      }),
    );

    setTimeout(() => {
      this.refresh();
    }, 1000);
  }

  refresh(options?: any) {
    this.showBanner = false;
    this.cdr.markForCheck();
    if (!this.authService.currentUser || this.authService.currentUser.miradiLicenseAcceptedDate) return;

    this.userService.defaultHeaders = this.userService.defaultHeaders.set('MS-IgnoreErrors', 'true');
    this.userService.getMiradiLicense()
    .subscribe((miradiLicense: MiradiLicense) => {
      if (!miradiLicense) return;

      this.miradiLicense = miradiLicense;
      this.showBanner = true;
      this.cdr.markForCheck();
    }, (error: any) => {

    }, () => {
      this.userService.defaultHeaders = this.userService.defaultHeaders.delete('MS-IgnoreErrors');
    });
  }

  review() {
    this.confirmDialog.show({
      title: `${this.translateService.instant('EULA')} - ${this.translateService.instant('Effective date')}: ${MomentUtils.getLocaleDate(this.miradiLicense.effectiveDate)}`,
      message: this.miradiLicense.content,
      noLabel: this.translateService.instant('Cancel'),
      yesLabel: this.translateService.instant('Accept'),
    });
    this.confirmDialog.onClose = (result: boolean) => {
      if (!result) return;

      this.userService.acceptMiradiLicense()
      .subscribe(() => {
        this.notificationService.success(
          this.translateService.instant('Success'),
          this.translateService.instant('EULA accepted successfully.')
        );

        this.authService.currentUser.miradiLicenseAcceptedDate = DateUtils.nowAsISOString();
        this.appService.emitEulaAccepted();

        this.showBanner = false;
        this.cdr.markForCheck();
      });
    };
  }

}

