import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from 'ag-grid-enterprise';
import 'hammerjs';
import { ContextMenuSub } from 'primeng/contextmenu';
import { OrderList } from 'primeng/orderlist';
import { Select } from 'primeng/select';
import { SplitButton } from 'primeng/splitbutton';
import { UITreeNode } from 'primeng/tree';
import { ObjectUtils } from 'primeng/utils';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Listbox } from 'primeng/listbox';
import { Accordion } from 'primeng/accordion';


Accordion.prototype.updateSelectionState = function() {
  if (this.tabs && this.tabs.length && this._activeIndex != null) {
    for (let i = 0; i < this.tabs.length; i++) {
      let selected = this.multiple() ? this._activeIndex.includes(i) : i === this._activeIndex;
      let changed = selected !== this.tabs[i].selected;

      if (changed) {
        this.tabs[i].loaded = selected; // this is the change I'm making in this overload...
        this.tabs[i].selected = selected;
        this.tabs[i].selectedChange.emit(selected);
        this.tabs[i].cd.markForCheck();
      }
    }
  }
}

ContextMenuSub.prototype.onItemClick = function(event: any, processedItem: any) {
  if (processedItem?.item?.disabled) return; // this line is new...

  this.getItemProp(processedItem, 'command', { originalEvent: event, item: processedItem.item });
  this.itemClick.emit({ originalEvent: event, processedItem, isFocus: true });
}

Listbox.prototype.onListKeyDown = function(event: KeyboardEvent) {
  // do nothing...we don't want keyboard support as it doesn't add much and breaks "space" key in image captions...
}

OrderList.prototype.onItemKeydown = function(event: KeyboardEvent) {
  // do nothing...we don't want keyboard support as it doesn't add much and breaks "space" key in image captions...
}

Select.prototype.hasSelectedOption = function() {
  return this.modelValue() !== undefined || (this.options || []).some(x => x.value === undefined);
}

Select.prototype.getOptionValue = function(option: any) {
  // return this.optionValue && this.optionValue !== null ? ObjectUtils.resolveFieldData(option, this.optionValue) : !this.optionLabel && option && option.value !== undefined ? option.value : option;
  return this.optionValue != null && this.optionValue !== '' ? ObjectUtils.resolveFieldData(option, this.optionValue)
  : !this.optionLabel && option && option.hasOwnProperty('value') ? option.value : option;
}

SplitButton.prototype.onDefaultButtonClick = function(event: Event) {
  // original event handler code
  // this.onClick.emit(event);

  // miradi updated event handler code
  if (this.onClick?.observers?.length) {
    this.onClick.emit(event);
  } else {
    this.onDropdownClick.emit(event);
    this.menu.toggle({currentTarget: this.containerViewChild.nativeElement, relativeAlign: this.appendTo == null});
  }
}

UITreeNode.prototype.onDropPointDragEnter = function(event: Event, position: number) {
  // if (this.tree.allowDrop(this.tree.dragNode, this.node, this.tree.dragNodeScope)) {
  //   if (position < 0)
  //     this.draghoverPrev = true;
  //   else
  //     this.draghoverNext = true;
  // }
}



if (environment.agGridLicenseKey) {
  LicenseManager.setLicenseKey(environment.agGridLicenseKey);
}

if (environment.googleAnalyticsKey) {
  // add Google Analytics script to <head>
  const googleAnalyticsScript1 = document.createElement('script');
  googleAnalyticsScript1.async = true;
  googleAnalyticsScript1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsKey

  const googleAnalyticsScript2 = document.createElement('script');
  googleAnalyticsScript2.async = true;
  googleAnalyticsScript2.innerHTML = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', '${environment.googleAnalyticsKey}');`;

  document.head.appendChild(googleAnalyticsScript1);
  document.head.appendChild(googleAnalyticsScript2);

  const emitEventToGA = (eventCategory: string, eventLabel: string) => {
    if (!eventCategory) return;

    (window as any).gtag('event', 'click', {
      event_category: eventCategory,
      event_label: eventLabel,
    });
  }
  // overriding the window.open method to send GA event for outbound "links"
  window.open = function (open) {
    return function (url, target, windowFeatures, e) {
      if ((url || '').toString().indexOf(window.location.origin) < 0) {
        const windowFeaturesSplit = (windowFeatures || '').split(',');
        const gaEventCategory = windowFeaturesSplit.find(x => x.indexOf('gaEventCategory=') >= 0);
        if (gaEventCategory) {
          windowFeatures = windowFeatures.replace(gaEventCategory, '');
          emitEventToGA(gaEventCategory.split('=')[1], url);
        } else {
          emitEventToGA('outbound', url);
        }
      }

      target = target || 'default_window_name'; // set name if missing here
      return open.call(window, url, target, windowFeatures);
    } as any;
  }(window.open);

  // handle all <a> click events to send GA event for outbound "links"
  document.addEventListener('click', (ev: any) => {
    const origin = ev.target.closest('a') || ev.target.closest('p-button');

    if (!origin) return false;
    if (
      !origin.dataset.gaeventcategory &&
      (
        !origin.href ||
        origin.href.indexOf('javascript:') >= 0 ||
        origin.href.indexOf(window.location.origin) >= 0
      )
    ) return false;

    emitEventToGA(origin?.dataset?.gaeventcategory, origin?.dataset?.gaeventlabel || origin.href);
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
  .catch(err => console.error(err));

