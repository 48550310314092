import { Injectable, NgModule } from '@angular/core';
import { AppSharedModule } from 'app/shared/app-shared.module';
import { EditorModule } from 'primeng/editor';
import Quill from 'quill';
import { RtfEditorComponent } from './rtf-editor.component';


@Injectable()
export class InitializeQuill {
  constructor() {
    const Inline: any = Quill.import('blots/inline');
    class BoldBlot extends Inline { };
    (BoldBlot as any).blotName = 'bold';
    (BoldBlot as any).tagName = 'b';

    class ItalicBlot extends Inline { }
    (ItalicBlot as any).blotName = 'italic';
    (ItalicBlot as any).tagName = 'i';

    class StrikeBlot extends Inline { }
    (StrikeBlot as any).blotName = 'strike';
    (StrikeBlot as any).tagName = ['s', 'strike'];

    Quill.register(BoldBlot);
    Quill.register(ItalicBlot);
    Quill.register(StrikeBlot);

    // HACK: All of the following snowtheme override is just to change the link placeholder from quilljs.com to miradishare.org...
    const SnowTheme: any = Quill.import('themes/snow');
    class MiradiTheme extends SnowTheme { }
    (MiradiTheme as any).DEFAULTS.modules.toolbar.handlers.link = function(value) {
      const tooltip = this.quill.theme.tooltip;
      if (value) {
        let range = this.quill.getSelection();
        if (range == null || range.length == 0) return;
        let preview = this.quill.getText(range);
        if (/^\S+@\S+\.\S+$/.test(preview) && preview.indexOf('mailto:') !== 0) {
          preview = 'mailto:' + preview;
        }
        tooltip.save = function() {
          this.root.className = 'ql-tooltip ql-editing';
          const value = this.textbox.value;
          const linkValidityRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
          switch (this.root.getAttribute('data-mode')) {
            case 'link': {
              if (!linkValidityRegex.test(value)) {
                this.root.className = 'ql-tooltip ql-editing invalid';
                return;
              }
              const scrollTop = this.quill.root.scrollTop;
              if (this.linkRange) {
                this.quill.formatText(this.linkRange, 'link', value, Quill.sources.USER);
                delete this.linkRange;
              } else {
                this.restoreFocus();
                this.quill.format('link', value, Quill.sources.USER);
              }
              this.quill.root.scrollTop = scrollTop;
              break;
            }
            default:
          }
          this.textbox.value = '';
          this.hide();
        };
        tooltip.edit('link', preview);
      } else {
        this.quill.format('link', false);
      }

      tooltip.root.className = 'ql-tooltip ql-editing';
      const input = tooltip.root.querySelector('input[data-link]');
      input.placeholder = 'https://miradishare.org';
      input.dataset.link = input.placeholder;
    };
    Quill.register(MiradiTheme);
  }
}

new InitializeQuill();



@NgModule({
  declarations: [
    RtfEditorComponent,
  ],
  exports: [
    RtfEditorComponent,
  ],
  imports: [
    AppSharedModule,
    EditorModule,
  ],
})
export class RtfEditorModule { }
