
export class MarketingPreferences {
  interests?: any[];
  subscriberStatus?: 'subscribed' | 'unsubscribed' | 'cleaned' | 'pending' | 'transactional' | 'archived';
}

export class MiradiUserPreferences {

  dismissedUxElements?: string[]; // 'new-feature-assumption', 'new-feature-output', 'new-feature-support', ...
  marketingPreferences?: MarketingPreferences;

  constructor(item?: Partial<MiradiUserPreferences>) {
    Object.assign(this, item);

    this.dismissedUxElements = this.dismissedUxElements || [];
    this.marketingPreferences = this.marketingPreferences || {};
  }

}
export class MiradiUserPreferencesWrapper {

  updatedDate?: string | null;
  value: MiradiUserPreferences;

  constructor(item?: Partial<MiradiUserPreferencesWrapper>) {
    Object.assign(this, item);

    this.value = new MiradiUserPreferences(this.value);
  }

}
