import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MiradiUserPreferencesWrapper } from 'app/shared/models/miradi-user-preferences.model';
import { environment } from 'environments/environment';
import { Observable, Observer, of } from 'rxjs';
import { catchError, delay, map, mergeMap } from 'rxjs/operators';
import { Program, ProgramPermission, ProgramsService, ProjectPermission, User, UserService } from '../api-generated';
import { GlobalBusyService } from '../global-busy/global-busy.service';
import { BrowserUtils } from 'app/shared/utils';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  allProgramsMap: { [key: string]: Program };
  allProgramPermissions: ProgramPermission[];
  currentUser: User;
  currentUserPreferences: MiradiUserPreferencesWrapper;
  currentProgramIdentifier: string;
  currentProgramPermission: ProgramPermission;
  currentProjectPermission: ProjectPermission;

  private inflightRequestTick: number;

  constructor(
    private globalBusyService: GlobalBusyService,
    private programsService: ProgramsService,
    private router: Router,
    private userService: UserService,
  ) {

  }

  loginOrRegister(endpoint: 'login' | 'register', userSearchArg?: string) {
    this.allProgramPermissions = undefined;

    const targetLinkUri = BrowserUtils.getQueryParams().target_link_uri;
    const timeZone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
    if (userSearchArg) window.location.href = `${environment.environmentBaseUrl}/${endpoint}?user_search_arg=${encodeURIComponent(userSearchArg)}${timeZone ? '&tz=' + encodeURIComponent(timeZone) : ''}${targetLinkUri ? '&target_link_uri=' + targetLinkUri : ''}`;
    else window.location.href = `${environment.environmentBaseUrl}/${endpoint}${targetLinkUri ? '?target_link_uri=' + targetLinkUri : ''}`;
  }

  discovery() {
    window.location.href = environment.environmentBaseUrl + '/discovery?target_link_uri=' + window.location.href;
  }

  logout() {
    this.allProgramPermissions = undefined;
    this.currentUser = undefined;
    window.location.href = `${environment.environmentBaseUrl}/logout?target_link_uri=${document.location.origin}${environment.appBaseHref}`;
  }

  getSessionUser(canUseCachedValue?: boolean): Observable<User> {
    if (canUseCachedValue && this.currentUser) {
      this.setAppInsightsUserContext(this.currentUser);

      return of(this.currentUser)
      .pipe(
        delay(1)
      );
    }

    // if we ever need to query the mod_auth session info directly:
    // this.httpClient.get(environment.environmentBaseUrl + '/redirectUri?info=json')
    return this.userService.getUser()
    .pipe(
      catchError((error: any) => {
        if (error.status !== 0 && error.status !== 401) {
          console.error(error);

          if ((window as any).appInsights) {
            (window as any).appInsights.trackException({
              exception: error.originalError || error
            });
          }
        }
        return of(null);
      }),
      mergeMap((response: User) => {
        this.currentUser = response;

        this.setAppInsightsUserContext(this.currentUser);

        if (this.currentUser) return this.userService.getUserPreferences();
        else return of(null);
      }),
      map((response: any) => {
        this.currentUserPreferences = new MiradiUserPreferencesWrapper(response);

        return this.currentUser;
      })
    );
  }

  private setAppInsightsUserContext(user: User) {
    if (!(window as any).appInsights) return;

    if (user) {
      (window as any).appInsights.setAuthenticatedUserContext(user.email, null, true);
    } else {
      (window as any).appInsights.clearAuthenticatedUserContext();
    }
  }

  getAllPrograms(): Observable<Program[]> {
    if (this.allProgramsMap) {
      return of(Object.values(this.allProgramsMap));
    } else {
      return this.programsService.getPrograms()
      .pipe(
        map((programs: Program[]) => {
          this.allProgramsMap = {};
          for (const p of programs || []) {
            this.allProgramsMap[p.identifier] = p;
          }
          return Object.values(this.allProgramsMap);
        })
      );
    }
  }

  getAllProgramPermissions(): Observable<ProgramPermission[]> {
    if (this.allProgramPermissions) {
      return of(this.allProgramPermissions);
    } else if (!this.inflightRequestTick) {
      this.inflightRequestTick = Date.now();
      return this.userService.getAllProgramPermissions()
      .pipe(
        map((pps: ProgramPermission[]) => {
          this.inflightRequestTick = undefined;

          this.allProgramPermissions = pps;
          return pps;
        })
      );
    } else {
      // a previous request is currently in progress, so try again in a few milliseconds to see if the result has become available
      return new Observable((observer: Observer<any>) => {
        setTimeout(() => {
          this.getAllProgramPermissions()
          .subscribe((response: any) => {
            observer.next(response);
            observer.complete();
          });
        }, 250);
      });
    }
  }

  getProgramPermissions(programIdentifier?: string, force?: boolean): Observable<ProgramPermission> {
    if (
      !force &&
      this.currentProgramPermission &&
      this.currentProgramPermission.programIdentifier === programIdentifier
    ) {
      return of(this.currentProgramPermission).pipe(delay(10));
    } else {
      this.globalBusyService.setBusy(true);
      return this.userService.getProgramPermissions(programIdentifier)
      .pipe(
        map((pp: ProgramPermission) => {
          this.currentProgramPermission = pp;
          this.globalBusyService.setBusy(false);
          return pp;
        })
      );
    }
  }

  getProjectPermissions(projectIdentifier: string, force?: boolean, skipSetBusy?: boolean): Observable<ProjectPermission> {
    if (
      !force &&
      this.currentProjectPermission &&
      this.currentProjectPermission.projectIdentifier === projectIdentifier
    ) {
      return of(this.currentProjectPermission).pipe(delay(10));
    } else {
      if (!skipSetBusy) this.globalBusyService.setBusy(true);
      return this.userService.getProjectPermissions(projectIdentifier)
      .pipe(
        map((pp: ProjectPermission) => {
          this.currentProjectPermission = pp;
          if (!skipSetBusy) this.globalBusyService.setBusy(false);
          return pp;
        })
      );
    }
  }

  setCurrentProgram(programIdentifier: string) {
    this.currentProgramIdentifier = programIdentifier;

    if (this.allProgramPermissions && this.allProgramPermissions.length) {
      this.currentProgramPermission = this.allProgramPermissions
      .find((pp: ProgramPermission) => {
        return pp.programIdentifier === programIdentifier;
      });
    }
  }

}
