import { ChangeDetectorRef, ElementRef, ViewChild, Component, Directive } from '@angular/core';
import { BaseComponent } from 'app/shared/components/base/base.component';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { ToastMessageOptions } from 'primeng/api';
import { Dialog } from 'primeng/dialog';


@Component({
    template: '<div></div>',
    standalone: false
})
export abstract class BaseDialog extends BaseComponent {

  @ViewChild(Dialog, { static: true }) dialog: Dialog;

  protected data: any;
  protected escapeKeyCallback: any;
  protected enterKeyCallback: any;
  isVisible: boolean;
  onClose: (data: any) => void;
  validationMessages: ToastMessageOptions[];

  constructor(
    authService: AuthService,
    protected cdr: ChangeDetectorRef,
    protected el: ElementRef,
  ) {
    super(authService);

    this.keyDownHandler = this.keyDownHandler.bind(this);
  }

  show(data?: any) {
    this.isVisible = true;
    this.data = data;
    this.init(this.data);

    this.bindDocumentKeyListeners();
    this.cdr.markForCheck();
  }

  protected abstract init(data: any);

  dismiss() {
    this.close(undefined);
  }

  protected close(data: any) {
    this.unbindDocumentKeyListeners();

    if (this.onClose) {
      this.onClose(data);
    }
    this.data = undefined;
    this.isVisible = false;

    this.cdr.markForCheck();
  }

  protected pushValidationMessage(msg: ToastMessageOptions) {
    this.validationMessages = this.validationMessages || [];
    this.validationMessages.push(msg);

    this.scrollTop();

    this.cdr.markForCheck();
  }

  protected getContentHeight(): number {
    const dialogContent = this.el?.nativeElement?.querySelector('.p-dialog-content');
    if (dialogContent) return dialogContent.clientHeight;
  }

  protected scrollTop() {
    const dialogContent = this.el?.nativeElement?.querySelector('.p-dialog-content');
    if (dialogContent) dialogContent.scrollTop = 0;
  }

  private bindDocumentKeyListeners() {
    const documentTarget: any = this.el ? this.el.nativeElement.ownerDocument : 'document';

    documentTarget.addEventListener('keydown', this.keyDownHandler);
  }

  private keyDownHandler(event: any) {
    if (event.keyCode == 27 && this.escapeKeyCallback) { // Escape
      this.escapeKeyCallback();
    } else if (event.which == 13 && this.enterKeyCallback) { // Enter
      this.enterKeyCallback();
    }
  }

  private unbindDocumentKeyListeners() {
    const documentTarget: any = this.el ? this.el.nativeElement.ownerDocument : 'document';

    documentTarget.removeEventListener('keydown', this.keyDownHandler);
  }

}
