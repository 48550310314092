<p-dialog
  styleClass="edit-photos"
  [header]="'Manage photos' | translate"
  [closable]="false"
  [closeOnEscape]="false"
  [modal]="true"
  [dismissableMask]="false"
  [(visible)]="isVisible">

  <p class="hint">
    {{ 'Click on a photo to manage it, use the arrow buttons to move it around. Captions can be edited directly below the photos. Changes become effective after the SAVE button is pressed.' | translate }}
  </p>

  <!-- I disabled the dragdrop as it's "broken" on recent primeng upgrade for horizontal lists-->
  <p-orderList
    [dragdrop]="false"
    [metaKeySelection]="true"
    (onReorder)="isDirty = true;"
    [(selection)]="selectedPhotos"
    (selectionChange)="selectedPhotos = $event; emptyFiles = [];"
    [value]="photos">
    <ng-template #item let-photo let-selected="selected">
      <div class="item-wrapper flex-col">
        <div *ngIf="photo.$imageLocalUrl || photo.imageThumbnailUrl"
          class="img"
          [style.backgroundImage]="'url(' + (photo.$imageLocalUrl ? photo.$imageLocalUrl : baseUrl + photo.imageThumbnailUrl) + ')'">
        </div>

        <p-button *ngIf="selectedPhotos && selectedPhotos[0] === photo"
          class="button-edit"
          icon="fas fa-pencil-alt"
          (click)="simulateFileInputClick(fileUpload.basicFileInput.nativeElement)"
          [raised]="true"
          [rounded]="true"
          severity="secondary">
        </p-button>

        <p-button *ngIf="selectedPhotos && selectedPhotos[0] === photo"
          class="button-delete"
          icon="fas fa-trash"
          (click)="deletePhotoLocally(photo)"
          [raised]="true"
          [rounded]="true"
          severity="secondary">
        </p-button>

        <input *ngIf="photo.resourceIdentifier || photo.$file"
          pInputText
          class="list-item-caption-input flex-1"
          [name]="photo.resourceIdentifier || photo.$file.name"
          type="text"
          [placeholder]="'Type to add caption...' | translate"
          [(ngModel)]="photo.caption"
          (ngModelChange)="isDirty = true;" />

        <div *ngIf="!photo.resourceIdentifier && !photo.$file"
          class="list-item-caption-label">
          {{ 'Click to add new' | translate }}
        </div>
      </div>
    </ng-template>
  </p-orderList>

  <div class="flex-row center"
    style="margin-bottom: 1rem;">
    <p-button
      class="button-new"
      icon="fas fa-plus"
      [label]="'Add Image' | translate"
      (click)="selectedPhotos = []; simulateFileInputClick(fileUpload.basicFileInput.nativeElement)"
      [raised]="true"
      severity="success">
    </p-button>

    <p-fileUpload #fileUpload
      hidden
      name="notUsed[]"
      url="notUsed"
      accept="image/png, image/jpeg, image/gif, image/bmp, image/tif"
      [maxFileSize]="maxFileSize"
      mode="basic"
      customUpload="true"
      (uploadHandler)="null"
      [files]="emptyFiles"
      (onSelect)="selectFile($event, selectedPhotos && selectedPhotos.length && selectedPhotos[0] ? selectedPhotos[0] : {}); fileUpload.clear()">
    </p-fileUpload>
  </div>

  <ng-template #footer>
    <p-button
      class="button-cancel"
      [text]="true"
      [label]="'Cancel' | translate"
      (click)="dismiss()">
    </p-button>

    <p-button
      icon="fas fa-check"
      [label]="'Save' | translate"
      [disabled]="!isDirty"
      (click)="save()"
      [raised]="true"
      severity="primary">
    </p-button>
  </ng-template>
</p-dialog>
