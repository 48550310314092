
export class JsonUtils {

  static deepFind(obj: any, path: string, createMissingNodes?: boolean, value?: any) {
    if (!path) return obj;

    const paths = path.split('.')
    let current = obj

    for (let i = 0; i < paths.length; ++i) {
      if (paths[i] == null || paths[i] === '') continue;

      if (!current) {
        return current;
      } else {
        if ((current[paths[i]] == null && createMissingNodes) || (i === paths.length - 1 && value !== undefined)) {
          current[paths[i]] = i < paths.length - 1 ? {} : value;
        }
        current = current[paths[i]];
      }
    }
    return current;
  }

}