import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { BaseComponent } from '../base/base.component';
import { CaseUtils, MomentUtils } from 'app/shared/utils';
import { SystemCachedService } from 'app/shared/services/app/system-cached.service';
import { SystemSettings } from 'app/shared/services';



@Component({
    selector: 'miradi-maintenance-banner',
    templateUrl: './maintenance-banner.component.html',
    styleUrls: ['./maintenance-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MaintenanceBannerComponent extends BaseComponent implements OnInit {

  maintenanceText: string;

  constructor(
    authService: AuthService,
    private cdr: ChangeDetectorRef,
    private systemCachedService: SystemCachedService,
  ) {
    super(authService);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.systemCachedService.getSettings()
      .subscribe((systemSettings: SystemSettings) => {
        this.maintenanceText = systemSettings.maintenanceBanner?.message || '';

        const datePlaceholderReplacements = this.maintenanceText.match(/{([^}]*)}/g) || [];
        for (const placeholderReplacement of datePlaceholderReplacements || []) {
          const replacementField = CaseUtils.camelize(placeholderReplacement.replace(/{/g, '').replace(/}/g, ''));
          const localDate = MomentUtils.getLocaleDateTime(systemSettings.maintenanceBanner[replacementField]);
          this.maintenanceText = this.maintenanceText.replace(placeholderReplacement, localDate);
        }

        this.cdr.markForCheck();
      }),
    );
  }

}

