<p-dialog styleClass="info-dialog {{ !!data?.imgSrc ? 'image-dialog' : '' }}"
  [appendTo]="appendTo"
  [header]="data?.title"
  [closable]="false"
  [closeOnEscape]="false"
  [modal]="true"
  [(visible)]="isVisible">

  <ng-container *ngTemplateOutlet="infoTemplate || defaultTemplate; context:{ }">
  </ng-container>

  <ng-template #defaultTemplate>
    <img *ngIf="data?.imgSrc"
      alt="Guidance image"
      class="image"
      [src]="data?.imgSrc"
      (load)="imageLoad()" />

    <div *ngIf="data?.message"
      class="content"
      [innerHTML]="data?.message">
    </div>
  </ng-template>

  <ng-template #footer>
    <p-button
      (click)="dismiss()"
      [label]="data?.buttonText || ('Close' | translate)"
      [raised]="true"
      severity="secondary">
    </p-button>
  </ng-template>
</p-dialog>
