import { Pipe, PipeTransform } from '@angular/core';
import { FactorInfo } from '../services';
import { MiradiUtils } from '../utils';

@Pipe({
    name: 'concatenateIdAndName',
    standalone: false
})
export class ConcatenateIdAndNamePipe implements PipeTransform {

  transform(factor: FactorInfo, keepLineBreaks): string {
    return factor ? MiradiUtils.concatenateIdAndNameV2(factor, keepLineBreaks) : '';
  }

}