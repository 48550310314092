import { Injectable } from '@angular/core';
import { JsonUtils } from 'app/shared/utils/json.utils';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { UserPreferences, UserService } from '../api-generated';
import { AuthService } from '../auth/auth.service';
import { MiradiUserPreferencesWrapper } from 'app/shared/models/miradi-user-preferences.model';


@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {

  constructor (
    private authService: AuthService,
    private userService: UserService,
  ) {

  }

  update(path: string, value: any): Observable<any> {
    // return of(this.authService.currentUserPreferences) // if we wanna skip the GET to test for conflicts...
    return this.userService.getUserPreferences()
    .pipe(
      mergeMap((response: UserPreferences) => {
        this.authService.currentUserPreferences = new MiradiUserPreferencesWrapper(response);

        JsonUtils.deepFind(response.value, path, true, value);

        return this.userService.updateUserPreferences(response);
      })
    );
  }
}
