<p-dialog
  styleClass="add-files"
  [closable]="false"
  [closeOnEscape]="false"
  [modal]="true"
  [dismissableMask]="false"
  [(visible)]="isVisible">

  <ng-template #header>
    <div>
      {{ title || (multiple ? ('Add File(s)' | translate) : ('Add File' | translate)) }}
    </div>

    <div class="flex-1"></div>

    <div>
      <p-button *ngFor="let action of extraActions"
        [text]="true"
        [icon]="action.icon"
        [pTooltip]="action.tooltip"
        tooltipPosition="left"
        tabindex="-1"
        (click)="action.requiresConfirm ? action.confirmMode = true : action.command()">
      </p-button>
    </div>

    <div *ngFor="let action of extraActions"
      [class.hidden]="!action.requiresConfirm || !action.confirmMode"
      class="confirm-header-bar">
      <div class="flex-row middle" style="height: 100%">
        <i class="icon fas fa-exclamation-circle"></i>

        <div>
          {{ action.confirmMessage }}
        </div>

        <div class="flex-1"></div>

        <p-button class="button-cancel"
          [text]="true"
          [label]="'Cancel' | translate"
          (click)="action.confirmMode = false">
        </p-button>

        <p-button
          icon="fas fa-trash"
          [label]="'Delete' | translate"
          (click)="action.command()"
          [raised]="true"
          severity="primary">
        </p-button>
      </div>
    </div>
  </ng-template>

  <div class="sub-header">
    {{ subTitle }}
  </div>

  <p-message *ngFor="let message of validationMessages || []"
    [closable]="false"
    [severity]="message.severity"
    [text]="message.summary + ' ' + message.detail">
  </p-message>

  <div class="content form">
    <div *ngIf="!extraFieldsAfterFileUploader">
      <miradi-form-field *ngFor="let extraField of extraFields"
        [disabled]="isInConfirmMode()"
        [editActive]="true"
        [label]="extraField.label"
        type="text"
        [validationObject]="extraField.validationObject"
        [(ngModel)]="extraField.value">
      </miradi-form-field>
    </div>

    <p-fileUpload #fileUpload
      [class.invalid]="missingFile"
      [class.extra-margin]="extraFieldsAfterFileUploader"
      [accept]="accept"
      [chooseLabel]="multiple ? ('Choose files' | translate) : ('Choose file' | translate)"
      customUpload="true"
      [disabled]="isInConfirmMode()"
      [maxFileSize]="maxFileSize"
      mode="advanced"
      [multiple]="multiple"
      name="notUsed[]"
      (onSelect)="missingFile = false;"
      [files]="emptyFiles"
      [showCancelButton]="false"
      [showUploadButton]="false"
      (uploadHandler)="null"
      url="notUsed">
      <ng-template pTemplate="content">
        <div *ngIf="!fileUpload.files || !fileUpload.files.length"
          class="empty-placeholder">
          {{ multiple ? ('Drag & drop files here' | translate) : ('Drag & drop file here' | translate) }}
        </div>
      </ng-template>
    </p-fileUpload>

    <div *ngIf="extraFieldsAfterFileUploader">
      <miradi-form-field *ngFor="let extraField of extraFields"
        [disabled]="isInConfirmMode()"
        [editActive]="true"
        [label]="extraField.label"
        type="text"
        [validationObject]="extraField.validationObject"
        [(ngModel)]="extraField.value">
      </miradi-form-field>
    </div>
  </div>

  <ng-template #footer>
    <p-button
      [text]="true"
      [disabled]="isInConfirmMode()"
      [label]="'Cancel' | translate"
      (click)="!isInConfirmMode() ? dismiss() : null">
    </p-button>

    <p-button
      icon="fas fa-check"
      [disabled]="isInConfirmMode()"
      [label]="'Save' | translate"
      (click)="!isInConfirmMode() ? save() : null"
      [raised]="true"
      severity="primary">
    </p-button>
  </ng-template>
</p-dialog>
