import { Pipe, PipeTransform } from '@angular/core';
import { EnumUtils } from '../utils';

@Pipe({
    name: 'toClassString',
    standalone: false
})
export class ToClassStringPipe implements PipeTransform {

  transform(text: string): string {
    return EnumUtils.toClassString(text);
  }

}